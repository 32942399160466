import React from 'react';

export const HomePage = () => {
  return (
    <div>
      <h1 className="text-[#d2a56d] m-0 p-[20px] font-poppins md:text-[74px] sm:text-[60px] lg:text-[100px]">
        magdalena
      </h1>
    </div>
  );
};
